import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {
      isLogin: false,
      username: '',
      identity: '',
      name: '',
      tel: ''
    },
  },
  getters: {
    toString(state) {
      let sf
      switch (state.user.identity) {
        case 0:
          sf = '普通用户'
          break
        case 1:
          sf = '管理员'
          break
        default: sf = '超级管理员'
      }
      return sf
    }
  },
  mutations: {
    setIslogin(state, payload) {
      state.user.isLogin = payload;
    },
    setUserInfo(state, payload) {
      if (payload.identity > 0) {
        state.user.name = payload.name
        state.user.tel = payload.tel
      }
      state.user.username = payload.username;
      state.user.identity = payload.identity;
    }
  },
  actions: {
  },
  modules: {
  }
})
